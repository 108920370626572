/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "GTM-PVD99RK",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Gowrav Krishna",
  logo_name: "Gowrav_Krishna.B()",
  nickname: "Gowrav / Krishna",
  full_name: "Gowrav Krishna Boyapati",
  subTitle: "Cloud Data Engineer at National Bank of Canada",
  resumeLink:
    "https://www.linkedin.com/in/gowravkrishnachowdaryboyapati/",
  mail: "mailto:gkb1884@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/GowravKrishnaBoyapati",
  linkedin:
    "https://www.linkedin.com/in/gowravkrishnachowdaryboyapati/",
  gmail: "gkb1884@gmail.com",
  facebook: "https://www.facebook.com/GOWRAVKRISHNACHOWDARY99/",
  twitter: "https://twitter.com/gowrav_chowdary",
  instagram: "https://www.instagram.com/gowrav_chowdary/",
};

const skills = {
  data: [
    {
      title: "Cloud Data Engineer",
      fileName: "",
      skills: [
        "⚡ Over 6+ years specializing in software analysis with a focus on Cloud technologies, particularly Azure. Proficient in Azure SQL, Data Lake, Data Factory, and AWS services like EMR, S3, and Redshift.",
        "⚡ Experienced in Big Data frameworks including Hadoop and Spark. Skilled in optimizing Spark jobs and managing ETL pipelines for efficient data processing.",
        "⚡ Expertise in ETL processes using IBM DataStage and tools like Spark, Kafka, and Hadoop. Capable of seamless data migration between on-premises and cloud environments. ",
        "⚡ Strong background in data modeling and optimization, ensuring efficient data handling and performance within diverse data environments.",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "Amazon Web Services",
          fontAwesomeClassname: "simple-icons:amazonwebservices",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#232F3E",
          },
        },
        {
          skillName: "AWS Lambda",
          fontAwesomeClassname: "simple-icons:awslambda",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Amazon S3",
          fontAwesomeClassname: "simple-icons:amazons3",
          style: {
            color: "#569A31",
          },
        },
        {
          skillName: "Amazon RDS",
          fontAwesomeClassname: "simple-icons:amazonrds",
          style: {
            color: "#527FFF",
          },
        },
        {
          skillName: "databricks",
          fontAwesomeClassname: "simple-icons:databricks",
          style: {
            color: "#FF3621",
          },
        },
        
        {
          skillName: "Apache Kafka",
          fontAwesomeClassname: "simple-icons:apachekafka",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#231F20",
          },
        },
        {
          skillName: "Apache Hadoop",
          fontAwesomeClassname: "simple-icons:apachehadoop",
          style: {
            color: "#66CCFF",
          },
        },
        {
          skillName: "Apache Hive",
          fontAwesomeClassname: "simple-icons:apachehive",
          style: {
            color: "#FDEE21",
          },
        },
        {
          skillName: "Apache Cassandra",
          fontAwesomeClassname: "simple-icons:apachecassandra",
          style: {
            color: "#1287B1",
          },
        },
        {
          skillName: "Apache Spark",
          fontAwesomeClassname: "simple-icons:apachespark",
          style: {
            color: "#E25A1C",
          },
        },
        {
          skillName: "Apache HBase",
          fontAwesomeClassname: "simple-icons:apachehbase",
          style: {
            color: "#BE160C",
          },
        },
         {
          skillName: "Scala",
          fontAwesomeClassname: "simple-icons:scala",
          style: {
            color: "#DC322F",
          },
        },
        {
          skillName: "Snowflake",
          fontAwesomeClassname: "simple-icons:snowflake",
          style: {
            color: "#29B5E8",
          },
        },
        
         {
          skillName: "Tableau",
          fontAwesomeClassname: "simple-icons:tableau",
          style: {
            color: "#E97627",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        // {
        //   skillName: "C++",
        //   fontAwesomeClassname: "simple-icons:cplusplus",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "K L University",
      subtitle: "Bachelor of Technology in Computer Science and Engineering",
      logo_path: "KLU.png",
      alt_name: "KLU",
      duration: "2014 - 2018",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, Signal Processing, Networking, Security, etc.",
        "⚡ I have also completed various online courses for AI , React JS , Web App Development, etc.",
        "⚡ I have implemented several projects based on what I've leart from AI related course, you can check my resume for more details ",
      ],
      website_link: "https://kluniversity.in",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Microsoft Certified: Azure Data Engineer Associate",
      subtitle: "Issued by Microsoft.",
      logo_path: "microsoft-certified-associate-badge.svg",
      certificate_link: "https://learn.microsoft.com/api/credentials/share/en-us/GowravKrishnaBoyapati/68D6FBB0B8437C6D?sharingId=63C40600E9BA4543",
      alt_name: "Microsoft Certified: Azure Data Engineer Associate",
      color_code: "#6eb8f5",
    },
    {
      title: "Data Science Professional Certificate",
      subtitle: "Issued by Courseera. Authorized by IBM",
      logo_path: "data-science-professional-certificate.png",
      certificate_link: "https://www.credly.com/badges/f7bbf4c7-0943-476d-9a8f-d3fdb46a59bd/public_url",
      alt_name: "Data Science Professional Certificate",
      color_code: "#d5c591",
    },
    {
      title: "IBM Applied AI Professional Certificate",
      subtitle: "Issued by Courseera. Authorized by IBM",
      logo_path: "ibm-applied-ai-professional-certificate.png",
      certificate_link: "https://www.credly.com/badges/b4c99c08-2f8d-4a38-925e-8b787532682c/public_url",
      alt_name: "IBM Applied AI Professional Certificate",
      color_code: "#d5c591",
    },

    {
      title: "IBM AI Foundations for Business Specialization",
      subtitle: "Issued by Courseera. Authorized by IBM",
      logo_path: "ibm-ai-foundations-for-business-specialization.png",
      certificate_link:"https://www.credly.com/badges/1ce08e9e-cecf-456a-98d5-d65a9ef1c295/public_url",
      alt_name: "IBM AI Foundations for Business Specialization",
      color_code: "#b2ff50",
    },
    {
      title: "Databases and SQL for Data Science",
      subtitle: "Issued by Courseera. Authorized by IBM",
      logo_path: "databases-and-sql-for-data-science.png",
      certificate_link:
        "https://www.credly.com/badges/62ce14d4-1619-42b9-a638-98f6e8f31d0d/public_url",
      alt_name: "Databases and SQL for Data Science",
      color_code: "#ffff",
    },

    {
      title: "Python for Data Science and AI",
      subtitle: "Issued by Courseera. Authorized by IBM",
      logo_path: "python-for-data-science-and-ai.png",
      certificate_link:
        "https://www.credly.com/badges/a59a3dab-1000-431e-ac6d-2b20a2fee62b/public_url",
      alt_name: "Python for Data Science and AI",
      // color_code: "#f36c3d",
      color_code: "#ffff",
    },
    {
      title: "Data Analysis with Python",
      subtitle: "Issued by Courseera. Authorized by IBM",
      logo_path: "data-analysis-with-python.png",
      certificate_link:
        "https://www.credly.com/badges/e8be7ba4-3aab-4f04-b4a3-569b61cce1fd/public_url",
      alt_name: "Data Analysis with Python",
      color_code: "#ffff",
    },

    {
      title: "Python for Data Science and AI",
      subtitle: "Issued by Courseera. Authorized by IBM",
      logo_path: "python-for-data-science-and-ai.png",
      certificate_link:
        "https://www.credly.com/badges/a59a3dab-1000-431e-ac6d-2b20a2fee62b/public_url",
      alt_name: "Python for Data Science and AI",
      // color_code: "#f36c3d",
      color_code: "#ffff",
    },
    {
      title: "Data Visualization with Python",
      subtitle: "Issued by Courseera. Authorized by IBM",
      logo_path: "data-visualization-with-python.png",
      certificate_link:
        "https://www.credly.com/badges/bca8ef1f-24c8-4ff9-a380-e69c92e396dd/public_url",
      alt_name: "Data Visualization with Python",
      color_code: "#ffff",
    },

    {
      title: "View more ->",
      subtitle: "",
      logo_path: "",
      certificate_link:
        "https://www.credly.com/users/gowrav-krishna-boyapati",
      alt_name: "",
      // color_code: "#f36c3d",
      color_code: "",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "",
  description:
    "I have over 6+ years of experience in software analysis, Cloud (Azure), Big Data (Hadoop, Spark), and ETL (IBM DataStage). Expertise includes Azure SQL, Data Lake, Data Factory, and AWS (EMR, S3, Redshift). Skilled in data modeling, ETL pipelines, optimizing Spark jobs, and managing data migration between on-premises and cloud environments using Spark, Kafka, and Hadoop tools.",
  header_image_path: "bookmark.png",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Cloud Data Engineer",
          company: "National Bank of Canada",
          company_url: "https://www.nbc.ca/",
          logo_path: "banquenationaleducanada_logo.jpeg",
          duration: "Feb 2024 - Present",
          location: "Remote",
          description:
            '  Worked closely with the business analysts to convert the Business Requirements into Technical Requirements and prepared low and high-level documentation. \n \
●  Strong experience of leading multiple Azure Big Data and Data transformation implementations in Banking domain\n \
●  Implemented large Lambda architectures using Azure Data platform capabilities like Azure Data Lake(ADLS),, Azure Data Factory, HDInsight, and Azure SQL Server.\n \
●  Implemented Azure data services such as Azure Synapse Analytics, Azure Data Factory, and Azure SQL Database to modernize data infrastructure and improve agility.\n \
●  Using Ansible tower integrate RESTFUL API with other systems or build custom automation workflows \n \
●  Designed and implemented Azure Data Governance solutions to ensure compliance with data privacy regulations such as GDPR and HIPAA.\n \
●  Developed and maintained data classification policies and procedures within Azure Information Protection (AIP) to secure sensitive data across the organization.\n \
●  Ensured data security and compliance with industry regulations by implementing Azure data governance features, including Azure Data Lake Storage and Azure Purview.\n \
●  Implemented monitoring and alerting mechanisms using Azure Monitor and Azure Log Analytics to proactively identify and address potential issues, ensuring high availability and reliability of data pipelines. \n \
●  Designing and implementing custom conflict resolution policies in multi-master configurations in Azure Cosmos DB.\n \
●  Led a team of data engineers and analysts in migrating legacy data systems to Snowflake, resulting in a 30% improvement in query performance and a 20% reduction in storage costs.\n \
●  Design and implement data architectures within Snowflake, including creating data warehouses, databases, schemas, and tables. \n \
●  Build ETL (Extract, Transform, Load) pipelines to ingest data from various source systems into Snowflake.\n \
●  Optimize data loading and query performance through techniques such as clustering, partitioning, and materialized views using Snowflake.\n \
●  Proficient in Azure Data Factory to perform Incremental Loads from Azure SQL DB to Azure Synapse. \n \
●  Experience in developing Spark applications using Spark-SQL in Databricks for data extraction, transformation, and aggregation from multiple file formats for Analyzing& transforming the data to uncover insights into the customer usage patterns.\n \
●  Responsible for estimating the cluster size, monitoring and troubleshooting of the spark Databricks cluster.\n \
●  Extract, transform and load the data from the source systems to Azure data storage services with combination of Azure Data Factory, T-SQL and Spark Sql and processing the data in Databricks.\n \
●  Analyzing the Data from different sources using Big Data Solution Hadoop by implementing Azure Data Factory, Azure Data Lake (ADLS), Azure Data Lake Analytics, HDInsight, Hive, and Sqoop.\n \
●  Prepared SQL scripts to test data, stored source code in Bitbucket.\n \
●  Analyzed legacy code in SSIS packages and implemented similar fine-tuned code in snowflake. \n \
●  Actively participated in migrating legacy servers to snowflake. \n \
●  Designed end to end scalable architecture to solve business problems using various Azure Components like HDInsight, Data Factory, Data Lake, Azure Monitoring, Key Vault, Function app and Event Hubs.\n \
●  Have good experience working with Azure BLOB and Data lake storage and loading data into Azure SQL Synapse analytics (DW).\n \
●  Created Pipelines in ADF using Linked Services/Datasets/Pipeline/ to Extract, Transform and load data from different sources like Azure SQL, Blob storage, Azure SQL Data warehouse, write-back tool and backwards.\n \
●  Enhanced reporting efficiency by generating new reports, maintaining existing ones, and optimizing performance for time-intensive reports using Azure Synapse Analytics and Power BI.\n \
●  Good experience in tracking and logging end to end software application build using Azure DevOps.\n \
●  Used Terraform script for deploying the applications for higher environments.\n \
●  Involved in various SDLC Life cycle phases like development, deployment, testing, documentation, implementation & maintenance of application software.\n \
●  Experience in transporting and processing real-time stream data using Kafka. \n \
',
          color: "#ff471a",
        },
        {
          title: "Cloud Data Engineer",
          company: "Manulife",
          company_url: "https://www.manulife.com/",
          logo_path: "manulife_financial_logo.jpeg",
          duration: "Mar 2021 - Jan 2024",
          location: "Remote",
          description:
            `Good experience in tracking and logging end to end software application build using Azure DevOps.
●	Led the design and implementation of ETL processes using Pentaho Data Integration on AWS for seamless data integration.
●	Engineered data pipelines in AWS Glue to automate and streamline data transformations and loading processes.
●	Implemented and optimized data warehouses on AWS Redshift, ensuring optimal performance and scalability.
●	Designed and implemented data pipelines to ingest data from various sources such as databases, APIs, and ﬁles into the AWS cloud using tools such as AWS Glue and AWS Data Pipeline.
●	Automated data ingestion, processing, and analysis using tools such as AWS Lambda and AWS Step Functions, resulting in a 50% reduction in processing time.
●	Developed and maintained ETL workﬂows to process and transform data using tools such as AWS EMR, AWS Lambda, and AWS Step Functions.
●	Utilized AWS EMR for distributed data processing, integrating with Pentaho for end-to-end data solutions.
●	Built and maintained data storage solutions on AWS such as S3, RDS, and DynamoDB, ensuring data integrity and security while adhering to best practices.
●	Provisioned EC2 instances to run data processing and analysis tasks. Conﬁgured and optimized EC2 instances for performance and cost eﬃciency.
●	Designed and implemented disaster recovery and backup solutions for data storage using tools such as AWS Backup and AWS CloudFormation.
●	Created and maintained data models and data dictionaries for business stakeholders, ensuring data accuracy and consistency.
●	Built data visualizations using tools such as SQL, Python, and R to help stakeholders make data-driven decisions.
●	Tuned data storage, processing, and analysis performance using tools such as AWS CloudFront, AWS CloudWatch, and AWS X-Ray, resulting in a 30% reduction in infrastructure costs.
●	Involved data migration from legacy systems to SAP S/4HANA. Cleanse and validate data to ensure accuracy and integrity.
●	Create a comprehensive mapping between the data fields in the legacy systems and the corresponding fields in SAP S/4HANA
●	Ensure that there is a clear understanding of how data will be transformed and loaded into the new system.
●	Monitor data quality in the SAP S/4HANA system after migration. Implement ongoing processes for data validation and correction.
●	Developed and maintained monitoring and alerting systems for data pipelines, storage, and analysis processes, ensuring timely issue resolution.
●	Worked closely with Data Scientists, Software Developers, and Business Analysts to understand their data requirements and build solutions that met their needs.
●	Generated workflows through Apache Airflow, then Apache Oozie for scheduling the Hadoop jobs which controls large data transformations.
●	Worked on reading and writing multiple data formats like JSON, ORC, Parquet on HDFS using Pyspark.
●	Perform Data Cleaning, features scaling, features engineering using pandas and NumPy packages in python.
●	Implement data streaming with Databricks Delta tables and Delta Lake to enable ACID transaction logging.
●	Create several types of data visualizations using Python and Tableau.
●	Worked on Python Open stack API's and used Python scripts to update content in the database and manipulate files.
●	Worked with OpenShift platform in managing Docker containers and Kubernetes Clusters.
●	Dealt with data ambiguity and performed lazy evaluation in PySpark for code optimization.
●	Create end-to-end solutions for ETL transformation jobs that involve writing Informatica workflows and mappings.
●	Helped individual teams to set up their repositories in bit bucket and maintain their code and help them setting up jobs which can make use of CI/CD environment.
●	Worked on stored procedures/views in Snowflake and use in Talend for loading Dimensions and Facts.
●	Involving in development of Python based Web Services using REST for sending and getting data from the external interface in the JSON format and to track sales and perform sales analysis using Django
●	Used Terraform script for deploying the applications for higher environments.
●	Involved in various SDLC Life cycle phases like development, deployment, testing, documentation, implementation & maintenance of application software.
●	Experience in transporting and processing real-time stream data using Kafka.
`,
          color: "#66ff1a",
        },{
          title: "Big Data Engineer",
          company: "HSBC Bank",
          company_url: "https://www.hsbc.com/",
          logo_path: "1717419425342.jpeg",
          duration: "Jun 2018 - Feb 2021",
          location: "Hybrid",
          description:
            `Participated in all phases including Analysis, Design, Coding, Testing and Documentation and gathered requirements and performed Business Analysis.
●	Developed Entity-Relationship diagrams and modeling Transactional Databases and Data Warehouse using ER/ Studio and Power Designer. 
●	Used big data tools Spark (Pyspark, SparkSQL) to conduct real-time analysis of the transaction. 
●	Migrated previously written cron jobs to airflow/composer in GCP 
●	Was involved in setting up of apache airflow service in GCP.
●	Design and Develop complex Data pipelines using Sqoop, Spark, and Hive to Ingest, transform and analyze customer behavior data.
●	Implemented Spark using Scala and utilizing Data frames and Spark SQL API for faster processing of data.
●	Maintained data pipeline up-time of 99.9% while ingesting streaming and transactional data across 7 different primary data sources using Spark, Redshift, S3, and Python.
●	Customized reports in JIRA for different teams to represent agile project statues and key metrics.
●	Ingested data from disparate data sources using a combination of SQL.
●	Google Analytics API, and Salesforce API using Python to create data views to be used in BI tools like Power BI.
●	Working with two different datasets one using HiveQL and other using Pig Latin.
●	Experience on moving the raw data between different systems using Apache Nifi.
●	Participated in building data lake in AWS.
●	Extract the data from source Hadoop system. Cleanse and transform the data as necessary and ensuring compatibility with the target Redshift using the AWS Glue
●	Run the Glue ETL jobs to Start Ingesting Data Into Redshift
●	Automating the data flow process using Nifi and hands-on experience on tracking the data flow in a real time manner using Nifi. 
●	Wrote terraform scripts for CloudWatch Alerts.
●	Developed data warehouse model in snowflake for over 100 datasets using where Scape and created Reports in Looker based on Snowflake Connections.
●	Writing MapReduce code using python in order to get rid of certain security issues in the data.
●	Synchronizing both the unstructured and structured data using Pig and Hive on business prospectus.
●	Implementing optimizations in Pentaho transformations and AWS services to enhance overall performance.
●	Implementing automation scripts and workflows using AWS Lambda functions and Pentaho scheduling.
●	Used Pig Latin at client-side cluster and HiveQL at server-side cluster.
●	Importing the complete data from RDBMS to HDFS cluster using Sqoop.
●	Worked on AWS environment and technologies such as S3, EC2, EMR, Glue, CFT, Lambda and databases Oracle, SMS, DynamoDB, MongoDB.  
●	Creating external tables and moving the data onto the tables from managed tables.
●	Performing the subqueries in Hive and partitioning and bucketing the imported data using HiveQL.
●	Moving this partitioned data onto the different tables as per as business requirements.
●	Invoking an external UDF/UDAF/UDTF python script from Hive using Hadoop Streaming approach which is supported by Ganglia.
●	Validating the data from SQL Server to Snowflake to make sure it has a correct match
●	Setting up the work schedule using oozie and identifying the errors in the logs, rescheduling/resuming the job.
●	Able to handle whole data using HWI (Hive Web Interface) using Cloudera Hadoop distribution UI.
●	Enhance the existing product with newly features like User roles (Lead, Admin, Developer), ELB, Auto scaling, S3, Cloud Watch, Cloud Trail and RDS-Scheduling.
●	Created monitors, alarms, and notifications for EC2 hosts using Cloud Watch, Cloud trail and SNS.
●	After the transformation of data is done, this transformed data is then moved to Spark cluster where the data is set to go live on to the application using Spark streaming and Kafka.
●	Created RDD’s in Spark technology.
●	Extracting data from data warehouse (Teradata)on to the Spark RDD’s,
●	Working on Stateful Transformations in Spark Streaming.
●	Good hands-on experience on Loading data onto Hive from Spark RDD’s.
●	Worked on Spark SQL UDF’s and Hive UDF’s also worked with Spark accumulators and broadcast variables.
●	Using decision tree as a model evaluation for both classification and regression.
●	Collaborated with the infrastructure, network, database, application, and BI teams to ensure data quality and availability. 
●	Developed, created and test environments of different applications by provisioning Kubernetes clusters on AWS using Docker, Ansible, and Terraform.
●	Developed and reviewed simultaneous SQL queries in Tableau desktop to validate both static and dynamic data for validation.
`,
          color: "#ff8080",
        },
      ],
    },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "Cross Winter of Code Mentor",
    //       company: "CWOC",
    //       company_url: "https://crosswoc.ieeedtu.in/",
    //       logo_path: "cwoc.png",
    //       duration: "Feb 2021 - Present",
    //       location: "Work From Home",
    //       description:
    //         "Mentorship responsibilities were to help students plan the project, review issues and pull requests, ensure smooth progress and help them out when they are stuck.",
    //       color: "#4285F4",
    //     },
    //     {
    //       title: "Campus Hustler",
    //       company: "Skillenza",
    //       company_url: "https://skillenza.com/",
    //       logo_path: "skillenza.png",
    //       duration: "Feb 2021 - Present",
    //       location: "Work from Home",
    //       description:
    //         "Spread Awareness of new Technologies and new Opportunities to Students and Grow Skillenza Community.",
    //       color: "#196acf",
    //     },
    //     {
    //       title: "GitHub Student Developer",
    //       company: "GitHub",
    //       company_url: "https://github.com/",
    //       logo_path: "github.png",
    //       duration: "Nov 2019 - Present",
    //       location: "Work from Home",
    //       description:
    //         "Contribute to Open Source Community and Open Source Project.",
    //       color: "#040f26",
    //     },
    //     {
    //       title: "Google Local Guide",
    //       company: "Google Map",
    //       company_url: "https://maps.google.com/localguides/",
    //       logo_path: "localguide.png",
    //       duration: "Sep 2018 - Present",
    //       location: "Work From Home",
    //       description:
    //         "Day-to-day responsibilities of helping local businesses to spread their business to the world. Helping users by writing reviews about different locations and spaces such as shops, malls, etc.",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "GDG Student Volunteer",
    //       company: "Google Developer Groups",
    //       company_url: "https://gdg.community.dev/",
    //       logo_path: "gdg.png",
    //       duration: "Feb 2021 - Present",
    //       location: "Work From Home",
    //       description:
    //         "Google Developer Group Surat Student Volunteer and Member.",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "E. F. I. Student Volunteer",
    //       company: "ENVIRONMENTALIST FOUNDATION OF INDIA",
    //       company_url: "https://indiaenvironment.org/",
    //       logo_path: "efi.png",
    //       duration: "Apr 2017 - Present",
    //       location: "Work From Home",
    //       description:
    //         "Take time out over weekends to join a gang of nation builders, nature enthusiasts to conserve what is left.",
    //       color: "#5a900f",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My best experience is to create Python Scripts, and React Project. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "blogs_image.svg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to share my source code so it can be helpful to others. I write on my Github.",
    link: "https://github.com/GowravKrishnaBoyapati",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Face-Mask-Detector",
      url: "https://youtu.be/bTxQP6PysRU",
      description:
        "This is an AI based application that detects wether a person has put on a face mask or not. This takes both Live and pre-recorded videos as input.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "1",
      name: "React-Facebook-Clone",
      url: "https://gowrav-facebook.web.app/",
      description:
        "A React Facebook Clone UI with basic functionality such as make a Post.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "2",
      name: "React-WhatsApp-Clone",
      url: "https://gowrav-whatsapp.web.app/",
      description:
        "A React WhatsApp Clone UI with basic functionality such as creating personal chat rooms/groups that every user can access and drop messages into it.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "3",
      name: "React-Hotstar-Clone",
      url: "https://gowrav-hotstar.web.app/",
      description:
        "A React Hotstar Clone UI with basic functionality such as displaying movie cards and giving the user a simillar experience of original build",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Redux",
          iconifyClass: "logos-redux",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "4",
      name: "personal-portfolio",
      url: "https://gowrav143.xyz",
      description:
        "A Personal Portfolio Website that showcases my work and experience.",
      languages: [
        {
          name: "Python",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "4",
      name: "Social-Distance-Detector",
      url: "https://youtu.be/Q6RZ9lvualA",
      description:
        "An AI based application that is used to detect wether the people in the video are maintaing social distance in the given input(either recorded video or live video from a camera)",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "6",
      name: "Human-Activity-Recognition",
      url: "https://youtu.be/UkLSXp4F58g",
      description:
        "Can detect about 300+ human actions taking input from camera or a recorded video",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "10",
      name: "COVID-19 Tracker",
      url: "https://gowrav-covid19-tracker.web.app/",
      description:
        "Simple Covid-19 Tracker made using React and deployed to Firebase.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
